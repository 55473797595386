import { LogoIcon } from '@/assets/icons';
import { useRouter } from 'next/router';
import { useGetMenus } from '@/hooks/apis';
import {
  HorizontalMenuItem,
  VerticalMenuItem,
} from '@/components/shared/layouts';
import Link from 'next/link';
import { LazyImage, MenuIcon } from '@/components/shared';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useTrans from '@/hooks/useTrans';
import { useGetSetting } from '@/hooks/apis/Setting/useGetSetting';
import { MENU_ICON_ID } from '@/constants';
import { useGetAllPage } from '@/hooks/apis/StaticPages/getAllSlugStaticPage';

const Header = () => {
  const trans = useTrans();
  const router = useRouter();

  const { data } = useGetMenus({ locale: router.locale });
  const { data: allMenu } = useGetMenus({ 'locale[$not]': 'null' });

  const { data: allPage } = useGetAllPage({
    'locale[$not]': 'null',
    populate: 'deep,3',
  });

  const ref = useRef<HTMLDivElement>(null);

  const [showMenu, setShowMenu] = useState(false);
  const closeMenu = useCallback(() => setShowMenu(false), []);
  const { data: DataInfo } = useGetSetting(router.locale || '');

  const changeLang = (lang: string) => {
    const currentPath = router.pathname;
    if (currentPath === '/thong-tin-doanh-nghiep') {
      router.push('/corporate-disclosures', undefined, {
        locale: lang,
        scroll: false,
      });
      return;
    }
    if (currentPath === '/corporate-disclosures') {
      router.push('/thong-tin-doanh-nghiep', undefined, {
        locale: lang,
        scroll: false,
      });
      return;
    }

    const menuPath = router.query?.slug;

    // code hơi dơ, fix sau nghen =))))

    const page = allMenu?.find(page => page?.slug === menuPath)?.page;

    const allStaticPage = allPage?.data?.find(
      (item: any) =>
        item?.attributes?.name === page && item?.attributes?.locale === lang,
    )?.attributes?.static_pages?.data;

    const slugPage =
      allMenu?.find(item => item?.page === page && item?.locale === lang)
        ?.slug || '/';

    const slugStatic = router.query.page
      ? allStaticPage?.find((item: any) => item?.attributes?.locale === lang)
          ?.attributes?.slug
      : '';

    router.push(`/${slugPage}/${slugStatic}`, undefined, {
      locale: lang,
      scroll: false,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        ref.current &&
        !ref.current.contains(target) &&
        target?.id !== MENU_ICON_ID
      ) {
        closeMenu && closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [closeMenu]);

  return (
    <div className="sticky inset-x-0 top-0 z-50 bg-bg-primary">
      <div className="container flex items-center justify-between pt-4 pb-4 px-[15px]">
        <Link href="/">
          {/* <LogoIcon className="w-[150px] lg:w-[220px]" /> */}
          {DataInfo.urlLogo && (
            <LazyImage
              alt="logo"
              src={DataInfo.urlLogo}
              width={182}
              height={53}
              className="object-contain"
            />
          )}
        </Link>

        <div>
          <p className="text-[22px] lg:text-[15px] text-[#A5A5A5] cursor-pointer">
            <span
              className={`${router.locale === 'vi' && 'text-primary'}`}
              onClick={() => changeLang('vi')}
            >
              VIE
            </span>
            |
            <span
              className={`${router.locale === 'en' && 'text-primary'}`}
              onClick={() => changeLang('en')}
            >
              EN
            </span>
          </p>
        </div>

        <MenuIcon
          className="md:hidden"
          showMenu={showMenu}
          onClick={() => setShowMenu(!showMenu)}
        />

        {showMenu && (
          <div
            className="absolute top-[80px] right-0 text-end bg-[#f0f0f1] w-2/3 h-screen"
            ref={ref}
          >
            {data?.map((menu, index) => (
              <VerticalMenuItem {...menu} closeMenu={closeMenu} key={index} />
            ))}
            <a className="px-4 text-second" href="#footer" onClick={closeMenu}>
              {trans.contactUs}
            </a>
          </div>
        )}
      </div>

      <div className="border-t border-b border-[#e2e2e2] hidden md:flex">
        <div className="container flex items-center justify-between">
          <div className="flex">
            {data?.map((menu, index) => (
              <HorizontalMenuItem {...menu} key={index} />
            ))}
          </div>

          <a className="text-second" href="#footer">
            {trans.contactUs}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
