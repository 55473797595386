import { useQuery } from 'react-query';
import { TYPE_QUERY_KEY } from '@/constants/typeQueryKey';
import { aboutUsApi, staticPageApi } from '@/services/apis';
import { IGetAllStaticPagesParams } from '@/interfaces/staticPage';

export const useGetAllSlugStaticPage = (params?: any) => {
  const { data } = useQuery(
    [TYPE_QUERY_KEY.GET_ALL_SLUGS_STATIC_PAGE, params],
    () => staticPageApi.getAllSlug(params),
  );

  return { data };
};

export const useGetAllPage = (params: any) => {
  return useQuery({
    queryKey: [TYPE_QUERY_KEY.GET_ALL_PAGE, params],
    queryFn: () => staticPageApi.getAllPages(params),
  });
};
